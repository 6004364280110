<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Main Colours</div>
                    <div class="card-body">
                        <div class="row row-cols-2 row-cols-md-3">
                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-primary mt-0 px-3 py-4 text-left">
                                    Primary
                                </div>
                                <div class="rounded-0 btn-block btn btn-primary active mt-0 px-3 py-4 text-left">
                                    Primary / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-primary disabled mt-0 px-3 py-4 text-left">
                                    Primary / Disabled
                                </div>
                            </div>

                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-secondary mt-0 px-3 py-4 text-left">
                                    Secondary
                                </div>
                                <div class="rounded-0 btn-block btn btn-secondary active mt-0 px-3 py-4 text-left">
                                    Secondary / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-secondary disabled mt-0 px-3 py-4 text-left">
                                    Secondary / Disabled
                                </div>
                            </div>

                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-success mt-0 px-3 py-4 text-left">
                                    Success
                                </div>
                                <div class="rounded-0 btn-block btn btn-success active mt-0 px-3 py-4 text-left">
                                    Success / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-success disabled mt-0 px-3 py-4 text-left">
                                    Success / Disabled
                                </div>
                            </div>

                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-danger mt-0 px-3 py-4 text-left">
                                    Danger
                                </div>
                                <div class="rounded-0 btn-block btn btn-danger active mt-0 px-3 py-4 text-left">
                                    Danger / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-danger disabled mt-0 px-3 py-4 text-left">
                                    Danger / Disabled
                                </div>
                            </div>

                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-warning mt-0 px-3 py-4 text-left">
                                    Warning
                                </div>
                                <div class="rounded-0 btn-block btn btn-warning active mt-0 px-3 py-4 text-left">
                                    Warning / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-warning disabled mt-0 px-3 py-4 text-left">
                                    Warning / Disabled
                                </div>
                            </div>

                            <div class="col mb-4">
                                <div class="rounded-0 btn-block btn btn-info mt-0 px-3 py-4 text-left">
                                    Info
                                </div>
                                <div class="rounded-0 btn-block btn btn-info active mt-0 px-3 py-4 text-left">
                                    Info / Hover
                                </div>
                                <div class="rounded-0 btn-block btn btn-info disabled mt-0 px-3 py-4 text-left">
                                    Info / Disabled
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Grays</div>
                    <div class="card-body">
                        <div class="row row-cols-1">
                            <div class="col mb-4">
                                <div class="bg-gray-100 px-3 py-4">
                                    Gray 100
                                </div>
                                <div class="bg-gray-200 px-3 py-4">
                                    Gray 200
                                </div>
                                <div class="bg-gray-300 px-3 py-4">
                                    Gray 300
                                </div>
                                <div class="bg-gray-400 px-3 py-4">
                                    Gray 400
                                </div>
                                <div class="bg-gray-500 px-3 py-4">
                                    Gray 500
                                </div>
                                <div class="bg-gray-600 px-3 py-4 text-light">
                                    Gray 600
                                </div>
                                <div class="bg-gray-700 px-3 py-4 text-light">
                                    Gray 700
                                </div>
                                <div class="bg-gray-800 px-3 py-4 text-light">
                                    Gray 800
                                </div>
                                <div class="bg-gray-900 px-3 py-4 text-light">
                                    Gray 900
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Colour',
}
</script>
